import React from 'react';
import Prismic from 'prismic-javascript';

import { Client } from '../../config/prismic';

export const TYPE_DOCUMENT_LINKS_BY_COUNTRY = 'documentlinksbycountry';
export const TYPE_GCC_POLICY_COUNTRY = 'gcc_policy_docs';
export const TYPE_DOCUMENT_LINKS_DEFAULT = 'documentlinksdefault';

const getDocumentsByCountry = (country) =>
  Client.query(Prismic.Predicates.at(`my.${TYPE_DOCUMENT_LINKS_BY_COUNTRY}.country`, country));

const getGCCCountryDocuments = () =>
  Client.query(Prismic.Predicates.at('document.type', `${TYPE_GCC_POLICY_COUNTRY}`));

const getDocumentsDefault = () =>
  Client.query(Prismic.Predicates.at('document.type', TYPE_DOCUMENT_LINKS_DEFAULT));

export const getDocumentLinksByCountry = (countryName) => {
  const [links, setLinks] = React.useState(null);
  const mountedRef = React.useRef(true);

  const specificPoliciesCountryList = [
    'Italia',
    'Canada',
    'France',
    'United States',
    'United Kingdom',
  ];
  const GCCPoliciesCountryList = [
    'United Arab Emirates',
    'Saudi Arabia',
    'Qatar',
    'Bahrain',
    'Kuwait',
    'Oman',
  ];

  const setResult = (result) => {
    if (mountedRef.current) {
      setLinks(result);
    }
  };

  // for footer links wee use https://emm-group.cdn.prismic.io
  React.useEffect(() => {
    const fetch = async () => {
      try {
        const [responseByCountry] = await Promise.all([
          // eslint-disable-next-line no-nested-ternary
          !specificPoliciesCountryList.includes(countryName) &&
          !GCCPoliciesCountryList.includes(countryName)
            ? getDocumentsDefault()
            : specificPoliciesCountryList.includes(countryName)
            ? getDocumentsByCountry(countryName)
            : getGCCCountryDocuments(),
        ]);

        console.log('debug-responseByCountry: ', responseByCountry);

        if (responseByCountry) {
          const country = responseByCountry.results[0];
          if (country) {
            setResult(country.data);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    fetch();

    return () => {
      mountedRef.current = false;
    };
  }, [countryName]);

  return { links };
};
