import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import { getDocumentLinksByCountry } from '../../utils/prismic';

const isClient = typeof window === 'object';

const Documents = ({ location, pageContext }) => {
  const { title, documentId } = pageContext;
  const countryName = isClient ? localStorage.getItem('countryName') : '';

  const { links } = getDocumentLinksByCountry(countryName);

  if (!links) {
    return null;
  }

  const documentLink = links[documentId];

  return (
    <Layout>
      <SEO title={title} />
      <div className="common-text-page">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-9 mx-auto">
              <div className="c-h1 text-center">{title}</div>
              <iframe
                title={title}
                width="100%"
                height="100%"
                src={documentLink && documentLink.url}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Documents.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    docPath: PropTypes.string,
  }).isRequired,
};

export default Documents;
